import router from './router'
// import store from './store'
import { getCookie, tokenInvalid } from '@/utils'

router.beforeEach((to, from, next) => {
  let token = getCookie(process.env.VUE_APP_TOKEN)
  if (token) {
    // 健全用户信息
    // store.dispatch('GetUserInfo')
    next()
  } else if (to.name === 'UserInfo') {
    tokenInvalid()
  } else {
    next()
  }
})
