<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" :fill="color" />
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return `svg-icon ${this.className}`
      }
      return 'svg-icon'
    }
  }
}
</script>
<style scope lang="scss">
.svg-icon {
  width: 20px;
  height: 20px;
  position: relative;
  fill: currentColor;
  vertical-align: -2px;
}
.icons-grey {
  color: $cust-font-color-2;
}
.icons-black {
  color: $font-color;
}
.icon-size-more {
  width: 9px;
  height: 12px;
  cursor: pointer;
}
.icon-size-6 {
  width: 6px;
  height: 6px;
}
.icon-size-9 {
  width: 9px;
  height: 9px;
}
.icon-size-18 {
  width: 18px;
  height: 18px;
}
.icon-size-19 {
  width: 19px;
  height: 19px;
}
.scroll-top-icon {
  width: 34px;
  height: 36px;
}
.icon-up-down {
  width: 7px;
  height: 11px;
}
</style>
