<template>
  <div v-if="currentPath.includes('/userInfo')">
    <Channel :path="currentPath" :baseUrl="baseUrl" />
    <CmsColumn v-if="showCmsPaths.includes(currentPath)" />
  </div>
</template>
<script>
import Channel from './Channel'
import CmsColumn from './CmsColumn'
export default {
  components: { Channel, CmsColumn },
  data() {
    return {
      showCmsPaths: ['/quotation/hsMarket', '/quotation/optional'],
      navList: [
        {
          title: '首 页',
          path: '/home'
        },
        {
          title: '行 情',
          path: '/quotation/hsMarket'
        },
        {
          title: '发 现',
          path: '/news'
        },
        {
          title: '下 载',
          path: '/download'
        }
      ],
      baseUrl: '',
      currentPath: this.$route.path
    }
  },
  watch: {
    $route(to) {
      this.currentPath = to.path
    }
  },
  mounted() {
    this.baseUrl = location.origin
  },
  methods: {
    goRoute(path) {
      path && this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 30px 0 20px;
  .logo {
    width: 122px;
    height: 50px;
    margin-right: 64px;
  }
  .nav {
    display: flex;
    li {
      margin: 0 25px;
      cursor: pointer;
      a {
        font-size: 25px;
        color: $cust-font-color-2;
      }
      &:hover,
      &.active-nav {
        color: $font-color;
        a {
          color: $font-color;
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    .user {
      margin: 0 31px;
      height: 50px;
    }
  }
}
</style>
