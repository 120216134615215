import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3ad86409&scoped=true&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=3ad86409&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_l4g5nlw2jejcj23wpoomwoyaia/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad86409",
  null
  
)

export default component.exports