import request from '@/utils/request'
import { NEWS,JRJBASE } from './constants'

// 频道列表
export const queryPartApi = (params) => {
  return request({
    url: NEWS + 'part/queryPart',
    headers: { 'Content-type': 'application/json' },
    params
  })
}

// 腾讯云上传授权
export const loadStsSecurityRole = () => {
  return request({
    url: JRJBASE + '/tencentOss/loadStsSecurityRole',
    headers: { 'Content-type': 'application/json' }
  })
}
// 腾讯云上传加密桶 
export const loadUserEncryptBucket = () => {
  return request({
    url: JRJBASE + '/tencentOss/loadUserEncryptBucket',
    headers: { 'Content-type': 'application/json' }
  })
}

