import { userInfoApi, getProvinceCityListApi } from '@/api/user'

const userCenter = {
  state: {
    userInfo: {
      avatar: '',
      nickName: '' // 昵称
      // userName: '' // 用户名
    },
    provinceCity: [],
    areas: []
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setProvinceCity(state, data) {
      state.provinceCity = data
    },
    setAreas(state, data) {
      state.areas = data
    }
  },
  actions: {
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        userInfoApi()
          .then((res) => {
            if (res.code === 20000) {
              const userInfo = res.data.user
              commit('setUserInfo', userInfo || state.userInfo)
              resolve({ userInfo })
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    GetProvinceCity({ commit, state }) {
      return new Promise((resolve, reject) => {
        getProvinceCityListApi()
          .then((res) => {
            if (res.code === 20000) {
              const provinceCity = res.data.data
              const areas = []

              provinceCity.filter((item) => {
                areas.push({
                  code: item.code,
                  name: item.name
                })
                return item
              })
              commit(
                'setProvinceCity',
                provinceCity ? provinceCity : state.provinceCity
              )
              commit('setAreas', areas ? areas : state.areas)
              resolve({ provinceCity: provinceCity, areas })
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  getters: {}
}

export default userCenter
