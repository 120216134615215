import config from "../../package.json";

export const productId = '6000021'
export function getDeviceInfo() {
  return JSON.stringify({
    productId,
    sourceType: "jrj-user-web",
    version: config.version,
    device: navigator.userAgent,
    sysName: getBrowser(),
    sysVersion: getBrowserInfo(),
  });
}

function getBrowser() {
  const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  const isOpera = userAgent.indexOf("Opera") > -1;
  if (isOpera) {
    //判断是否Opera浏览器
    return "Opera";
  }
  if (userAgent.indexOf("Firefox") > -1) {
    //判断是否Firefox浏览器
    return "Firefox";
  }
  if (userAgent.indexOf("Chrome") > -1) {
    //判断是否Chrome浏览器
    return "Chrome";
  }
  if (userAgent.indexOf("Safari") > -1) {
    //判断是否Safari浏览器
    return "Safari";
  }
  if (
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1 &&
    !isOpera
  ) {
    //判断是否IE浏览器
    return "IE";
  }
}

function getBrowserInfo() {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ie = /msie [\d.]+;/gi;
  const regStr_ff = /firefox\/[\d.]+/gi;
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStr_saf = /safari\/[\d.]+/gi;
  //IE11以下
  if (agent.indexOf("msie") > 0) {
    return agent.match(regStr_ie);
  }
  //IE11版本中不包括MSIE字段
  if (agent.indexOf("trident") > 0 && agent.indexOf("rv") > 0) {
    return "IE " + agent.match(/rv:(\d+\.\d+)/)?.[1];
  }
  //firefox
  if (agent.indexOf("firefox") > 0) {
    return agent.match(regStr_ff);
  }
  //Chrome
  if (agent.indexOf("chrome") > 0) {
    return agent.match(regStr_chrome);
  }
  //Safari
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
    return agent.match(regStr_saf);
  }
}
