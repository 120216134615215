import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/userInfo'
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    meta: {
      title: '个人中心'
    },
    component: () =>
      import(/* webpackChunkName: "userInfo" */ '@/views/userInfo/index.vue')
  },
  {
    path: '/forgetPswd',
    name: 'ForgetPswd',
    meta: {
      title: '忘记密码'
    },
    component: () => import('views/login/forgetPswd.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title: '隐私政策'
    },
    component: () => import('views/agreements/privacy/index.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {
      title: '服务协议 '
    },
    component: () => import('views/agreements/service/index.vue')
  },
  {
    path: '/sdk',
    name: 'sdk',
    meta: {
      title: '第三方SDK目录'
    },
    component: () => import('views/agreements/sdk/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
