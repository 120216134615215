import Cookie from 'js-cookie'
import { Base64 } from 'js-base64'

// 设置cookie
export const setCookie = (key, value, expires = 365) => {
  Cookie.set(key, value, {
    expires,
    path: '/',
    domain: process.env.VUE_APP_DOMAIN
  })
}

// token过期清空登录人信息并且显示登录弹窗
export const tokenInvalid = () => {
  window.clearUser && window.clearUser()
  window.showLogin && window.showLogin()
}

// 获取cookie
export const getCookie = (key) => {
  const token = Cookie.get(key)
  if (token) return token
  else return ''
}

// 删除cookie
export const removeCookie = (key) => {
  Cookie.remove(key,{
    path: '/',
    domain: process.env.VUE_APP_DOMAIN
  })
}

// 存缓存重写
export const storage = {
  getItem: (key) => {
    return localStorage.getItem(key)
      ? JSON.parse(Base64.decode(localStorage.getItem(key) || ''))
      : ''
  },
  setItem: (key, value) => {
    localStorage.setItem(key, Base64.encode(JSON.stringify(value)))
  },
  removeItem: (key) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  }
}

//获取上传文件重命名
export const setFileName = (fileName) => {
  const start = fileName.lastIndexOf('.') + 1
  fileName = fileName.substring(start)
  return getNowTime() + getRandomNum(5) + '.' + fileName
}

// 获取年月日时分秒毫秒
export const getNowTime = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minute =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const second =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  const milliSeconds = date.getMilliseconds()
  const currentTime =
    year +
    '' +
    month +
    '' +
    day +
    '' +
    hour +
    '' +
    minute +
    '' +
    second +
    '' +
    milliSeconds
  return currentTime
}

// 获取随机数
export const getRandomNum = (n) => {
  if (n > 21) return null
  return ((Math.random() + 1) * Math.pow(10, n - 1)).toFixed(0)
}

// // 加载外部js
// export const loadJs = (src, domId) => {
//   return new Promise((resolve, reject) => {
//     let script = document.createElement('script')
//     // script.type = 'text/javascript'
//     script.src = src
//     document.getElementById(domId)?.appendChild(script)
//     script.onload = () => {
//       resolve({})
//     }
//     script.onerror = () => {
//       reject()
//     }
//   })
// }

export const CloseWebPage = () => {
  if (navigator.userAgent.indexOf('MSIE') > 0) {
    if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
      //适用于ie6不带提示关闭窗口
      window.opener = null
      window.close()
    } else {
      window.open('', '_top') //ie7之后不提示关闭窗口
      window.top.close()
    }
  } else if (navigator.userAgent.indexOf('Firefox') > 0) {
    //Firefox不提示关闭窗口
    window.location.href = 'about:blank '
  } else {
    window.opener = null
    window.open('', '_self', '') //其他浏览器
    window.close()
  }
}
