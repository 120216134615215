<template>
  <div class="app">
    <Headers />
    <AgreeHeader v-if="isShow === true" />
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Header/index'
import AgreeHeader from '@/components/AgreeHeader/index'

// import { loadJs } from '@/utils/index'
export default {
  components: { Headers, AgreeHeader },
  data() {
    return {
      isShow: false
    }
  },
  beforeCreate() {
    if (
      window.location.href.indexOf('/service') !== -1 ||
      window.location.href.indexOf('/privacy') !== -1 ||
      window.location.href.indexOf('/sdk') !== -1
    ) {
      document.getElementById('usecenter-header').style.display = 'none'
      document.getElementById('usecenter-footer').style.display = 'none'
    }
  },
  mounted() {
    if (
      window.location.href.indexOf('/service') !== -1 ||
      window.location.href.indexOf('/privacy') !== -1 ||
      window.location.href.indexOf('/sdk') !== -1
    ) {
      this.isShow = true
    } else {
      this.isShow = false
    }
    // // const scriptInfo = document.createElement('script')
    // // scriptInfo.type = 'text/javascript'
    // // scriptInfo.src =
    // //   'https://js.jrjimg.cn/cloud/js/common/header-t-quotation.js'
    // // document.head.appendChild(scriptInfo)
    // this.$nextTick(() => {
    //   //   // let script = document.createElement('script')
    //   //   // script.type = 'text/javascript'
    //   //   // script.src = src
    //   //   // document.getElementById(domId)?.src=""
    //   loadJs('https://js.jrjimg.cn/cloud/js/common/header-t.js', 'headers')
    // })
  }
}
</script>
<style lang="scss">
.app {
  padding-top: 0.1px;
}
</style>
