<template>
  <div class="column-header custom-tab">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="{ title, id, url } in columns"
        :key="id"
        :label="title"
        :name="id"
      >
        <template #label>
          <a :href="url" target="_blank">{{ title }}</a>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: '',
      columns: [
        {
          title: '股  票',
          id: '10011',
          url: ''
        },
        {
          title: '研究院',
          id: '10021',
          url: ''
        },
        {
          title: '元宇宙',
          id: '10031',
          url: ''
        },
        {
          title: '巨灵财经',
          id: '10041',
          url: ''
        },
        {
          title: '行业洞察',
          id: '10051',
          url: ''
        },
        {
          title: '龙虎榜',
          id: '10061',
          url: ''
        },
        {
          title: '涨跌停温度计',
          id: '10071',
          url: ''
        },
        {
          title: '#数字经济再度...#',
          id: '10081',
          url: ''
        },
        {
          title: '领航中国',
          id: '10091',
          url: ''
        },
        {
          title: '爱投顾',
          id: '100111',
          url: ''
        },
        {
          title: '#数字经济再度...#',
          id: '100112',
          url: ''
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.column-header {
  width: 1200px;
  margin: 0 auto;
  :deep(.el-tabs) {
    .el-tabs__item {
      padding: 0;
      &:nth-child(2) {
        padding-left: 0 !important;
      }
      a {
        display: inline-block;
        padding: 4px 10px;
        line-height: 19px;
        color: $color-theme2;
      }
      &.is-active {
        border: 1px solid #fff;
        background-color: $color-primary-light-9;
        color: $color-theme2;
        a {
          color: $color-theme2;
        }
      }
    }
  }
}
</style>
