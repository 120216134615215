import { render, staticRenderFns } from "./Channel.vue?vue&type=template&id=992ec1d2&scoped=true&"
import script from "./Channel.vue?vue&type=script&lang=js&"
export * from "./Channel.vue?vue&type=script&lang=js&"
import style0 from "./Channel.vue?vue&type=style&index=0&id=992ec1d2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_l4g5nlw2jejcj23wpoomwoyaia/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "992ec1d2",
  null
  
)

export default component.exports