import Vue from 'vue'
import ElementUI, { Message } from 'element-ui'

import App from './App.vue'
import router from './router'
import store from './store'
import './permission.js'
// 公共样式引入
import './styles/ui-rewrite.scss'
import './styles/common.scss'
import './assets/icons/index'

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$Message = Message
Vue.prototype.$store = store
Vue.prototype.$staticUrl = process.env.VUE_APP_BASE_URL_STATIC
Vue.prototype.$fileBaseUrl = process.env.VUE_APP_BASE_URL_PLATFORM
Vue.prototype.$fileUserPersonalDataBaseUrl = process.env.VUE_APP_BASE_URL_USER_PERSONAL_DATA

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
