import request from '@/utils/request'
import { USER, JRJBASE } from './constants'
// 创作者登录创作平台 
export const creatorLoginApi = (params) => {
  return request({
    url: USER + '/creator/getAutorToken',
    params,
    headers: { 'Content-type': 'application/json', loading: false }
  })
}
// 获取创作者状态
export const currentStatusApi = () => {
  return request({
    url: USER + '/creator/currentStatus',
    headers: { 'Content-type': 'application/json', loading: false }
  })
}

// 创作者上传资料 
export const creatorApplyApi = (params) => {
  return request({
    url: USER + '/creator/apply',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}
// 获取当前实名信息 
export const currentRealInformationApi = () => {
  return request({
    url: USER + '/user/currentRealInformation',
    headers: { 'Content-type': 'application/json', loading: false }
  }) 
}

// 检验实名信息 
export const checkRealInformationApi = (params) => {
  return request({
    url: USER + '/user/checkRealInformation',
    params,
    headers: { 'Content-type': 'application/json', loading: false }
  }) 
}
// 获取个人信息
export const userInfoApi = () => {
  return request({
    url: USER + '/user/getUserinfo',
    // url: USER + '/user/getCurrUser',
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 修改个人信息
export const updateUserApi = (params) => {
  return request({
    url: USER + '/user/updateUser',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 修改头像
export const updateAvatar = (params) => {
  return request({
    url: USER + '/user/updateAvatar',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 获取图片验证码
export const imageCaptchaApi = () => {
  return request({
    url: USER + '/captcha/imageCaptcha',
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 获取短信验证码（已登录）
export const loginSendCodeApi = () => {
  return request({
    url: USER + '/captcha/loginSendCode',
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 图片验证码校验
export const checkCaptchaApi = (params) => {
  return request({
    url: USER + '/captcha/checkCaptcha',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 校验短信验证码（已登录）
export const loginCheckCodeApi = (params) => {
  return request({
    url: USER + '/captcha/loginCheckCode',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 初始绑定手机号
export const setPhoneApi = (params) => {
  return request({
    url: USER + '/user/setPhone',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 设置初始密码
export const setPasswordApi = (params) => {
  return request({
    url: USER + '/user/setPassword',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 修改
export const updatePasswordApi = (params) => {
  return request({
    url: USER + '/user/updatePassword',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 更换绑定手机号
export const updatePhoneApi = (params) => {
  return request({
    url: USER + '/user/updatePhone',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 腾讯云验证码获取短信验证码（未登录）
export const sendCodeApi = (params) => {
  return request({
    url: USER + '/captcha/sendCodeByTicket',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}
// 获取短信验证码（未登录）
export const sendCodeApiOld = (params) => {
  return request({
    url: USER + '/captcha/sendCode',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 校验短信验证码（未登录）
export const checkCodeApi = (params) => {
  return request({
    url: USER + '/captcha/checkCode',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 忘记密码-重置密码
export const resetPasswordApi = (params) => {
  return request({
    url: USER + 'user/resetPassword',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}

// 获取省市区三级联动数据
export const getProvinceCityListApi = (params) => {
  return request({
    url: JRJBASE + 'site/getProvinceCityList',
    params,
    headers: { 'Content-type': 'application/json', loading: true }
  })
}
