/**
 * @version 1.0.0
 * @since 2023/4/27
 */
export const codeEnum = {
  // 公共配置
  Success: 20000, // 请求成功
  Error: 50000, // 请求异常
  // 登录相关
  TokenIsNull: 40100 // 未登录/token过期
}
// 功能模块-上传文件目录
export const moduleDir = {
  subject: '/platform/image/activity/',
  user: '/platform/image/',
  userencrypt: '/platform/userencrypt/image/' // 加密桶中的路径
}
