<template>
  <div class="channel-header header-c-nav">
    <ul class="nav-ul">
      <li
        v-for="({ name, url, list, showH1, ...otherItem }, i) in navList"
        :key="name + i"
        :class="setMoreClass(name, url)"
        @click="!url && name === '更 多' && handleMore(list)"
      >
        <div class="one-line more-name" v-if="!url && name === '更 多'">
          {{ name }}
        </div>
        <template v-else>
          <h1 v-if="showH1">
            <a class="one-line" :href="url" target="_blank">{{ name }}</a>
          </h1>
          <a v-else class="one-line" :href="url" target="_blank">{{ name }}</a>
        </template>
        <div v-if="url" class="line"></div>
      </li>
    </ul>
    <div class="channel-more-box">
      <ul v-if="showMore">
        <li v-for="({ name, url, showH1, ...otherItem }, i) in moreList" :key="name + i"
        :style="setMoreStyle(name, url, otherItem)"
        >
          <h1 v-if="showH1">
            <a :href="url" target="_blank">{{ name }}</a>
          </h1>
          <a v-else :href="url" target="_blank">{{ name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { queryPartApi } from 'api/common'
export default {
  props: {
    path: {
      type: String,
      default: '/quotation/hsMarket'
    },
    baseUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showMore: false,
      navList: [],
      moreList: []
    }
  },
  created() {
    queryPartApi({ channelNum: '999', infoCls: '002015' }).then((res) => {
      this.navList = res.data.data
    })
  },
  methods: {
    // 更多样式
    setMoreStyle(name, url, otherItem) {
      let style = {}
      if (otherItem?.width) {
        style.width = otherItem.width
      }
      return style
    },
    // 更多样式
    setMoreClass(name, url) {
      let className = ''
      // 更多
      if (!url && name === '更 多') {
        className = 'channel-more'
        if (this.showMore) {
          className += ' arrows-t'
        }
      }
      return className
    },
    // 点击更多
    handleMore(list) {
      this.showMore = !this.showMore
      this.moreList = list
    }
  }
}
</script>
<style lang="scss" scoped>
.channel-header {
  background-color: $color-theme;
  width: 1200px;
  height: 50px;
  margin: 0 auto;
  box-sizing: border-box;
}
.header-c-nav {
  background: #c01639;
  height: 50px;
  margin-top: 20px;
  position: relative;
  ul.nav-ul {
    overflow: hidden;
    li {
      float: left;
      font-size: 21px;
      line-height: 50px;
      height: 50px;
      cursor: pointer;
      width: 107.5px;
      text-align: center;
      box-sizing: border-box;
      .item-name {
        display: inline-block;
      }
      a {
        width: 105px;
        display: inline-block;
      }
      a,
      .more-name {
        color: #ffffff;
        &:hover {
          text-decoration: underline;
        }
      }
      &.channel-more {
        width: auto;
        padding: 0 28px;
        background: url('//i0cloud.jrjimg.cn/cloud/images/common/arrows-b.png')
          no-repeat 82px center;
      }
      &.channel-more.arrows-t {
        background: url('//i0cloud.jrjimg.cn/cloud/images/common/arrows-t.png')
          no-repeat 82px center;
      }
    }
    .line {
      float: right;
      width: 2px;
      height: 14px;
      opacity: 0.3;
      background: #231916;
      margin-top: 18.5px;
    }
  }
  .channel-more-box {
    position: absolute;
    top: 54px;
    right: 0;
    width: 217px;
    z-index: 11;
    background: url('//i0cloud.jrjimg.cn/cloud/images/common/menu-more.png')
      no-repeat;
    background-size: 100% 100%;
    ul {
      overflow: hidden;
      padding: 24px 0 0 16px;
      li {
        float: left;
        font-size: 17px;
        cursor: pointer;
        padding-right: 16px;
        padding-bottom: 19px;
        width: 67px;
        a {
          color: #595757;
          &:hover {
            color: #c01639;
            text-decoration: underline;
          }
        }
        &.cur {
          a {
            color: #c01639;
          }
        }
      }
    }
  }
}
</style>
