import axios from 'axios'
import qs from 'qs'
import { getDeviceInfo, productId } from './getDeviceInfo'
import { Loading } from 'element-ui'
// import { Loading, MessageBox,Message } from 'element-ui'

import { codeEnum } from './codeEnum'
import { getCookie, tokenInvalid } from '@/utils'
// import router from '@/router'
// import { Base64 } from 'js-base64'

// 当前正在请求的数量
let requestCount = 0

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 25000
})

// 开始加载动画
let loading
function startLoading() {
  if (requestCount === 0) {
    loading = Loading.service({
      lock: true, // 是否锁定
      text: '拼命加载中...', // 加载中需要显示的文字
      background: 'transparent', // 背景颜色
      customClass: 'global-loading'
    })
  }
  requestCount++
}
// 结束加载动画
function endLoading() {
  if (requestCount > 0) {
    requestCount--
    loading.close() //清除加载效果
  }
}

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.productId = productId
    config.headers.DeviceInfo = getDeviceInfo()
    const token = getCookie(process.env.VUE_APP_TOKEN)
    if (token) {
      config.headers.jrjToken = token
    }
    if (config.headers.loading) {
      startLoading() // 请求时的加载动画
    }
    return config
  },
  (error) => {
    endLoading() // 出错时关闭加载动画
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  (response) => {
    // 响应时的关闭动画
    endLoading()
    const { code } = response.data
    if (code === codeEnum.TokenIsNull) {
      // 未登录
      tokenInvalid()
      // window.showLogin && window.showLogin()
      // let title = '登录超时'
      // let message = '登录已超时，请重新登录。'
      // if (response.data.code === codeEnum.TokenIsNull) {
      //   title = '未登录'
      //   message = '您未登录，请先登录'
      // }
      // MessageBox.alert(message, title, {
      //   confirmButtonText: '去登录',
      //   callback: () => {
      //     const url = window.location.href
      //     router.push({
      //       path: '/login',
      //       query: {
      //         redirectPath: Base64.encode(url)
      //       }
      //     })
      //   }
      // })
      return Promise.reject(response.data)
    }
    if (
      response.request.responseType === 'blob' ||
      response.request.responseType === 'arraybuffer'
    ) {
      return response.data
    }
    if (code !== codeEnum.Success) {
      // Message({ message: msg, type: 'warning' })
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data)
  },
  (error) => {
    endLoading()
    return Promise.reject(error)
  }
)

async function request(opts) {
  // 参数opts不是一个对象
  if (Object.prototype.toString.call({}).slice(8, -1) !== 'Object') {
    return console.error('参数不正确！')
  }

  opts.method = opts.method || 'post'

  if (['post', 'put', 'patch'].includes(opts.method.toLocaleLowerCase())) {
    const { url, method, params, headers, responseType, onUploadProgress } =
      opts
    try {
      if (headers) {
        const res = await service({
          url,
          method,
          data: params,
          headers,
          responseType,
          onUploadProgress
        })
        return res
      } else {
        const res = await service({
          url,
          method,
          data: qs.stringify(params),
          responseType,
          onUploadProgress
        })
        return res
      }
    } catch (err) {
      return err
    }
  } else {
    try {
      const res = await service(opts)
      return res
    } catch (err) {
      return err
    }
  }
}
export default request
